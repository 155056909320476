



























































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import Product from "@/models/Product"
import ConstantTool from "@/services/tool/ConstantTool"
import LoginService from "@/services/LoginService"
import SnackbarModule from "@/store/SnackbarModule"
import ProductService from "@/services/ProductService";
import SessionModule from "@/store/SessionModule";

@Component
export default class ProductDialog extends Vue {

	@Prop() readonly dialog!: boolean
	@Prop() readonly product!: Product
	@Prop({ default: true }) readonly isPvp!: boolean
	sessionModule: SessionModule = getModule(SessionModule)
	lang: any = getModule(LangModule).lang
	BASE_URL: string = ConstantTool.BASE_URL
	image: string = ""

	get rateId(): number { return this.sessionModule.session.user.rateId! }
	get price() { return Number(this.product.rates![this.rateId! - 1].price).toFixed(2) }
	get pricePvp() { return Number(this.product.commercialRate!).toFixed(2) }
	get	total() { return (Number(this.product.rates![this.rateId! - 1].price) * (this.product!.quantity!)).toFixed(2) }
	get totalPvp() { return Number(this.product.commercialRate! * this.product!.quantity!).toFixed(2) }

	get isLoged(): boolean { return LoginService.isLoged() }

	created() {
		this.product.quantity = 1
		this.refresh()
	}

	addProduct() {
		this.$emit('add-product', this.product)
		this.$emit('close')
		this.image = ""
	}

	proceedToCheckout() {
		this.addProduct()
		this.$router.push('/cart')
	}

	errorHandler() {
		this.image = "../assets/ban.svg"
		this.$forceUpdate()
	}

	refresh() {
		this.image = `${this.BASE_URL}/storage/ftp/art_${this.product.connectorId?.toLowerCase()}_1_tmb.jpg`
	}

	addFavourite() {
		if (this.isLoged) { getModule(SnackbarModule).makeToast(`El producto ha sido marcado como favorito.`) }
		else { this.$router.push("/login") }
	}
}
