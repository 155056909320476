

















































































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator"
import Product from "@/models/Product"
import Order, {OrderType} from "@/models/Order"
import ProductService from "@/services/ProductService"
import {getModule} from "vuex-module-decorators"
import SnackbarModule from "@/store/SnackbarModule"
import OrderDetail from "@/models/OrderDetail"
import AddressService from "@/services/AddressService"
import Address from "@/models/Address"
import Client from "@/models/Client"
import ClientService from "@/services/ClientService"
import RouterTool from "@/services/tool/RouterTool"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import SessionModule from "@/store/SessionModule"
import ClientDialog from "@/components/dialog/ClientDialog.vue"
import ProductCategory from "@/models/ProductCategory"
import LangModule from "@/store/LangModule"
import OrderDetailListComponent from "@/components/OrderDetailListComponent.vue"
import CategoryTreeviewComponent from "@/components/CategoryTreeviewComponent.vue"
import ProductCategoryService from "@/services/ProductCategoryService";
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule";
import ProductListComponent from "@/components/ProductListComponent.vue";
import TestTreeview from "@/components/TestTreeview.vue";
import BudgetService from "@/services/BudgetService";
import Budget, {BudgetType} from "@/models/Budget";
import PotentialClientService from "@/services/PotentialClientService";
import CreatePotentialClientDialog from "@/components/dialog/CreatePotentialClientDialog.vue";
import CreateAddressDialog from "@/components/dialog/CreateAddressDialog.vue";
import ProductDialog from "@/components/dialog/ProductDialog.vue";

@Component({components: { ClientDialog, OrderDetailListComponent, CategoryTreeviewComponent, ProductListComponent, TestTreeview, CreatePotentialClientDialog, CreateAddressDialog, ProductDialog }})
export default class ProductView extends Vue {

	@Ref() readonly form!: HTMLFormElement
	lang: any = getModule(LangModule).lang
	sessionModule: SessionModule = getModule(SessionModule)
	categoryTreeviewModule = getModule(CategoryTreeviewModule)

	get productCategories() { return this.categoryTreeviewModule.productCategories }
	get selectedCategories() { return this.categoryTreeviewModule.selectedCategories }
	set selectedCategories(categoryIds: number[]) { this.categoryTreeviewModule.setSelectedCategories(categoryIds) }
	get isEdit() { return this.$route.name == this.lang.editAnBudget && this.isDraft }
	get isDraft() { return (this.budget.budgetType == BudgetType.DRAFT) }
	get orderIsEmpty(): boolean { return this.budget.budgetDetails?.length == 0 }
	get pages() {return Math.ceil(this.totalItems / this.itemsPerPage)}

	isDistributor = this.sessionModule.session.authorities.map(e => e.title == "distributor")[0]
	addressRules = [ (v: Address) => v ? true : this.lang.fieldRequired ]
	clientRules = [ (v: Client) => v ? true : this.lang.fieldRequired ]

	clientDialog: boolean = false
	addressDialog: boolean = false
	productDialog: boolean = false

	tab: number = 0
	tabs: string[] = ["/products", "/drafts"]

	budget: Budget = new Budget()
	Budgets: Budget[] = []
	potentialClients: Client[] = []
	potentialClient: Client = new Client()
	product: Product = new Product()
	products: Product[] = []
	items: Product[] = []
	shippingAddresses: Address[] = []

	page: number = 1
	loading: boolean = false
	search: string = ""
	totalItems: number = 0
	itemsPerPage: number = 10
	potentialClientSearch: string = ""
	pvp: boolean = false

	created() {
		if (this.$route.params.orderId != undefined) {
			this.getBudget()
			this.watchClient()
		}
		this.refresh()
	}

	refresh() {
		ProductCategoryService.getProductCategoriesTest(this, this.productCategories)
		this.getProducts()
		this.getPotentialClients()
	}

	async getPotentialClients() {
		if (this.isDistributor) {
			await PotentialClientService.getPotentialClients(this, this.potentialClients, 0, 10, "", false, true)
		} else {
			await PotentialClientService.getMyPotentialClients(this, this.potentialClients, 0, 10, "", true, this.sessionModule.session!.user!.id!)
		}
	}

	getProducts() {
		ProductService.getProducts(this, this.products, this.page - 1, this.itemsPerPage, this.search, this.selectedCategories, null, null, null)
	}

	async getBudget() {
		await BudgetService.getBudget(this, Number(this.$route.params.orderId))
	}

	addBudgetDetail(product: Product) {
		//Si el producto ya ha sido añadido anteriormente, isAlreadyAdded sería -1.
		let isAlreadyAdded = this.budget.budgetDetails?.findIndex(od => od.product?.id == product.id)

		if (isAlreadyAdded != -1) {
			getModule(DialogModule).showDialog(new Dialog(
				 this.lang.warning, this.lang.alreadyAddedProductMessage,
				 async () => {
					 if (product.quantity == 0) {
						 this.budget.budgetDetails![isAlreadyAdded!].quantity = 1
						 product.quantity = 1
					 } else {
						 this.budget.budgetDetails![isAlreadyAdded!].quantity = product.quantity
					 }
				 }
			))
		} else {
			const orderDetail = new OrderDetail()
			orderDetail.product = product
			if (product.quantity == 0) {
				orderDetail.quantity = 1
				product.quantity = 1
			} else {
				orderDetail.quantity = product.quantity
			}

			this.budget.budgetDetails?.push(orderDetail)
		}
	}

	openProductDialog(product: Product) {
		console.log(product)
		this.product = product
		this.productDialog = true
	}

	removeOrderDetail(orderDetail: OrderDetail) {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.removeProductFromBudgetConfirmation,
			 () => {
				 this.budget.budgetDetails = this.budget.budgetDetails!.filter(od => od.id != orderDetail.id)
			 }
		))
	}

	async createOrder(send: boolean = false) {
		await this.draft()
	}

	async createOrderConfirmation() {
		this.removeNullOrderDetails()
		if (this.form.validate()) {
			if (this.orderIsEmpty) { getModule(SnackbarModule).makeToast(this.lang.addProductToContinue) }
			else {
				getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.placeOrderQuestion, async () => {
						 await this.createOrder()
						 await this.$router.push("/my-budgets")
					 }
				))
			}
		}
	}

	async saveOrderConfirmation() {
		if (this.form.validate()) {
			if (this.orderIsEmpty) { getModule(SnackbarModule).makeToast(this.lang.addProductToContinue) }
			else {
				getModule(DialogModule).showDialog(new Dialog(
					 this.lang.warning,
					 this.$route.params.orderId ? this.lang.saveOrderQuestion : this.lang.newlyOrderSendWarning,
					 async () => {
						 await this.saveAnOrder()
						 await this.$router.push("/my-budgets")
					 }
				))
			}
		}
	}

	async placeAnOrderConfirmation() {
		this.removeNullOrderDetails()
		if (this.form.validate()) {
			if (this.orderIsEmpty) { getModule(SnackbarModule).makeToast(this.lang.addProductToContinue) }
			else {
				getModule(DialogModule).showDialog(new Dialog(
					 this.lang.warning, this.lang.placeBudgetConfirmation, async () => {
						 await this.placeAnOrder()
						 await this.$router.push("/my-budgets")
					 }
				))
			}
		}
	}

	async saveAnOrder() {
		this.removeNullOrderDetails()
		if (this.form.validate()) {
			if (this.orderIsEmpty) {getModule(SnackbarModule).makeToast(this.lang.addProductToContinue) }
			else {
				getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.saveBudgetConfirmation, async () => {
						 await this.patchBudget()
						 await this.draft()
						 await this.$router.push("/my-budgets")
					 }
				))
			}
		}
	}

	async placeAnOrder() {
		await this.draft()
		await this.patchBudget()
	}

	async patchBudget() {
		await BudgetService.patchBudget(this, this.budget)
	}

	async sendBudget() {
		if (this.form.validate()) {
			await this.patchBudget()
			getModule(DialogModule).showDialog(new Dialog(
				 this.lang.warning, this.lang.sendBudgetConfirmation, async () => {
					 await BudgetService.sendBudget(this, this.budget.id!)
					 await this.$router.push("/my-budgets")
				 })
			)
		}
	}

	removeNullOrderDetails() {
		this.budget.budgetDetails = this.budget.budgetDetails!.filter(orderDetail => orderDetail.quantity != 0)
	}

	async draft() {
		await BudgetService.draftBudget(this, this.budget)
	}

	clearSearch() {
		this.search = ""
		this.refresh()
	}

	onQuantityChanged(orderDetail: OrderDetail) {
		let index = this.budget.budgetDetails!.indexOf(orderDetail)
		this.budget.budgetDetails![index!].quantity = orderDetail.quantity
	}

	@Watch("budget.potentialClient")
	watchClient() {
		if (this.budget.potentialClient?.id) {
			AddressService.getAddressesByPotentialClient(this, this.shippingAddresses, this.budget.potentialClient!.id!)
		}
	}

	@Watch("selectedCategories")
	onSelectedCategoriesChanged() {
		this.getProducts()
		this.page = 1
	}

	@Watch('page')
	onPageChanged() {
		this.refresh()
	}

}
